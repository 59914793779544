import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from 'react-router-dom';
import store from './store'
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.css";
import "./index.css";
import App from "./App";

ReactDOM.render(
  <Provider store={store}>
	<BrowserRouter>
      <App />
	</BrowserRouter>
  </Provider>,
  document.getElementById("root")
);